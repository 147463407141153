<template>
  <div class="voucher">
    <div class="voucher-wrapper">
      <div class="voucher">
        <img src="@/assets/images/promotion/referFriends/voucher_container_line.png" alt="img" />
        <div class="voucher-text">
          <div class="amount">
            <p>{{ currentInfo.openAccount.amount }}</p>
          </div>
          <div class="status">
            <p>{{ currentInfo.openAccount.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="voucher-wrapper">
      <div class="voucher">
        <img src="@/assets/images/promotion/referFriends/voucher_container_line.png" alt="img" />
        <div class="voucher-text">
          <div class="amount">
            <p>{{ currentInfo.despositSuccess.amount }}</p>
          </div>
          <div class="status">
            <p>{{ currentInfo.despositSuccess.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="voucher-wrapper">
      <div class="voucher">
        <img src="@/assets/images/promotion/referFriends/voucher_container.png" alt="img" />
        <div class="voucher-text">
          <div class="amount">
            <p>{{ currentInfo.volumeCompleted.amount }}</p>
          </div>
          <div class="status">
            <p>{{ currentInfo.volumeCompleted.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Voucher',
  props: {
    tier: Number,
    type: String
  },
  computed: {
    referrerMap() {
      return {
        openAccount: {
          amount: '$20',
          text: this.$t('promotion.referFriends.table.openAccount')
        },
        despositSuccess: {
          amount: this.tier === 1 ? '$50' : '$30',
          text: this.$t('promotion.referFriends.table.despositSuccess')
        },
        volumeCompleted: {
          amount: this.tier === 1 ? '$80' : '$50',
          text: this.$t('promotion.referFriends.table.volumeCompleted')
        }
      };
    },
    refereeMap() {
      return {
        openAccount: {
          amount: this.tier === 1 ? '$20' : '$10',
          text: this.$t('promotion.referFriends.table.openAccount')
        },
        despositSuccess: {
          amount: this.tier === 1 ? '$30' : '$20',
          text: this.$t('promotion.referFriends.table.despositSuccess')
        },
        volumeCompleted: {
          amount: this.tier === 1 ? '$50' : '$20',
          text: this.$t('promotion.referFriends.table.volumeCompleted')
        }
      };
    },
    currentInfo() {
      return this.type === 'referrer' ? this.referrerMap : this.refereeMap;
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/promotion/voucher.scss';
</style>
