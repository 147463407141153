<template>
  <div class="promotion">
    <DialogVersion3 :visible.sync="visible" visibleFooter hideChat>
      <div class="top">
        <p class="title--blue">{{ $t('promotion.referFriends.dialog.confirmQ') }}</p>
        <p class="title--grey">{{ $t('common.column.amt') }}</p>
        <p class="title--blue">${{ amount }} USD</p>
      </div>
      <el-form :model="Form" ref="Form" :rules="FormRules">
        <el-form-item :label="$t('common.field.accNum')" prop="formAccount">
          <el-select v-model="Form.formAccount" class="select--RAF">
            <el-option
              v-for="item in formAccountOptions"
              :key="item.mt4_account"
              :value="item.mt4_account"
              :label="item.mt4_account + ' - ' + item.currency"
              :data-testid="item.mt4_account"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="form-box">
        <div class="info-wrapper">
          <div class="info1">
            {{
              $t('promotion.referFriends.dialog.confirmQ2', {
                amount: redeemableAmountToRate,
                currency: redeemCurrency
              })
            }}
          </div>
          <div class="info2" v-if="overBonus">{{ $t('promotion.referFriends.dialog.overBonus') }}</div>
        </div>
        <div class="btn-wrapper">
          <el-button
            class="btn-red"
            @click="submitRedeem"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            :disabled="isRedeemDisabled"
            data-testid="submitRedeem"
          >
            {{ $t('promotion.referFriends.dialog.redeem') }}
          </el-button>
        </div>
        <div class="agreeTnc-wrapper">
          <i18n path="promotion.referFriends.dialog.agree">
            <template v-slot:link>
              <a :href="tncLink" target="_blank" data-testid="tnc">
                {{ $t('openAdditAcc.tnc') }}
              </a>
            </template>
          </i18n>
        </div>
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { apiQuery_mt_accounts, apiExchangeRate, apiReferralSubmitRedeem } from '@/resource';

export default {
  name: 'ChooseAccount',
  props: {
    chooseAccountVisible: Boolean,
    amount: Number,
    qualifiedIDList: Array,
    overBonus: Boolean
  },
  components: { DialogVersion3 },
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      visible: false,
      rate: null,
      defaultCurrency: 'USD',
      redeemCurrency: 'USD',
      Form: {
        formAccount: ''
      },
      formAccountOptions: [],
      selectedAccount: {}
    };
  },
  watch: {
    chooseAccountVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      if (bool) {
        this.fetchTradingAccount();
      }
      this.disableChooseAccountVisible(bool);
    },
    'Form.formAccount'(number) {
      const foundAccount = this.formAccountOptions.find(f => f.mt4_account === number);
      this.redeemCurrency = foundAccount.currency;
      this.getApiReferralExchangeRate(this.redeemCurrency);
    },
    formAccountOptions() {
      if (this.formAccountOptions.length > 0) this.Form.formAccount = this.formAccountOptions[0].mt4_account;
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    isRedeemDisabled() {
      return !this.amount || this.overBonus;
    },
    tncLink() {
      return this.regulator === 'svg'
        ? `https://${this.GLOBAL_DOMAIN_WEBSITE}/pdf/Pacific_Union_RAF_Terms_and_Conditions_Net.pdf?v=1613980797`
        : `https://${this.GLOBAL_DOMAIN_WEBSITE}/pdf/0528/Pacific_Union_RAF_Terms_and_Conditions.pdf?v=1613980797`;
    },
    FormRules() {
      return {
        formAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ]
      };
    },
    redeemableAmountToRate() {
      return (this.amount * this.rate).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  },
  methods: {
    disableChooseAccountVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:chooseAccountVisible', bool);
      });
    },
    fetchTradingAccount() {
      apiQuery_mt_accounts({
        supportedCurrencies: 'all'
      }).then(resp => {
        if (resp.data.code === 0) {
          // ACM-7756 to avoid using mt4/mt5 PAMM account for redeeming
          this.formAccountOptions = resp.data.data.filter(
            account => account.currency !== 'USC' && !account.accountTypeDisplay.includes('PAMM')
          );
        }
      });
    },
    getApiReferralExchangeRate(redeemCurrency) {
      apiExchangeRate({ from: this.defaultCurrency, to: redeemCurrency }).then(res => {
        this.rate = res.data.data;
      });
    },
    postApiReferralSubmitRedeem() {
      apiReferralSubmitRedeem({
        participantIds: this.qualifiedIDList,
        mt4Account: this.Form.formAccount,
        currency: this.redeemCurrency
      })
        .then(resp => {
          if (resp.data.code === 0) {
            if (resp.data.data) {
              this.disableChooseAccountVisible(false);
              this.$emit('successfulCallback');
            } else {
              this.$message({
                message: this.$t('promotion.failed'),
                type: 'error'
              });
            }
          }
        })
        .catch(err => {
          this.$message({
            message: this.$t('promotion.failed'),
            type: 'error'
          });
        });
    },
    submitRedeem() {
      this.$refs['Form'].validate(valid => {
        if (valid) {
          this.postApiReferralSubmitRedeem();
        } else {
          console.log('error');
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/promotion.scss';
</style>
