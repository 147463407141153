<template>
  <div>
    <el-row type="flex" justify="center" v-if="isReferrer && showReferrer">
      <el-col :md="12" :sm="14">
        <div class="intro-text">
          {{ $t('promotion.referFriends.intro2') }}
        </div></el-col
      >
    </el-row>
    <el-row type="flex" justify="center" v-if="isReferrer && showReferrer">
      <el-col :md="2" :sm="3" :xs="6">
        <img src="@/assets/images/promotion/dash.png" class="image-dash" alt="img"
      /></el-col>
    </el-row>
    <div class="referee-section">
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :md="14" :sm="24" :xs="24">
          <el-row>
            <el-col :span="24">
              <div class="box">
                <div class="text-blue">{{ $t('promotion.referFriends.shareLink') }}</div>
                <div class="text-grey">{{ $t('promotion.referFriends.shareLinkDescp') }}</div>
                <el-row class="link-wrapper" :gutter="20">
                  <el-col :md="19" :sm="15"><el-input v-model="referralLink"> </el-input></el-col>
                  <el-col :md="5" :sm="9">
                    <el-button
                      class=" btn-blue"
                      v-clipboard:copy="referralLink"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      data-testid="copy"
                    >
                      {{ $t('promotion.referFriends.share') }}</el-button
                    >
                  </el-col>
                </el-row>
                <AddToAny :link="referralLink" v-if="referralLink"></AddToAny>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="box total-box">
                <div class="text-blue">{{ $t('promotion.referFriends.total') }}</div>
                <div class="info-wrapper">
                  <el-row type="flex" justify="space-between">
                    <el-col :span="5">
                      <div class="info-label">
                        {{ $t('promotion.referFriends.clients') }}
                      </div>
                      <div class="info-image">
                        <img src="@/assets/images/promotion/referFriends/clients.png" alt="img" />
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <Number :number="redeemedReferralCount"></Number>
                    </el-col>
                  </el-row>
                </div>
                <div class="info-wrapper">
                  <el-row type="flex" justify="space-between">
                    <el-col :span="5">
                      <div class="info-label">
                        USD
                      </div>
                      <div class="info-image">
                        <img src="@/assets/images/promotion/referFriends/USD.png" alt="img" />
                      </div>
                    </el-col>
                    <el-col :span="19">
                      <Number :number="totalRedeemedVoucher"></Number>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :sm="24" :xs="24" v-show="smallDevicesMatches">
              <MobileBox></MobileBox>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="10" v-show="!smallDevicesMatches">
          <MobileBox></MobileBox>
        </el-col>
      </el-row>
    </div>
    <div class="table-section">
      <el-row :gutter="20">
        <el-col :md="19" :sm="24" :xs="24">
          <div class="table-box">
            <el-table :data="tableData" style="width: 100%;" height="336" header-cell-class-name="table-header">
              <el-table-column
                prop="participantId"
                :label="$t('promotion.referFriends.table.referralsNo')"
                min-width="120"
              >
                <template slot-scope="scope">
                  {{ tableData.indexOf(scope.row) + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="realName" :label="$t('promotion.referFriends.table.name')" min-width="120">
              </el-table-column>
              <el-table-column prop="countryId" :label="$t('promotion.referFriends.table.country')" min-width="120">
                <template slot-scope="scope">
                  {{ getCountryName(scope.row.countryId) }}
                </template>
              </el-table-column>
              <el-table-column prop="status" :label="$t('promotion.referFriends.table.openAccount')" min-width="120">
                <template slot-scope="scope">
                  <!-- https://yapi.test-vantagefx.com/project/24/interface/api/1083 -->
                  <div v-if="['RO', 'RD', 'VR', 'RT', 'RR'].includes(scope.row.status)">
                    <img :src="voucherImage(1, scope.row.tier, 'active')" alt="img" />
                  </div>
                  <div v-else-if="['RE', 'X'].includes(scope.row.status)">
                    <img :src="require(`@/assets/images/promotion/referFriends/redeemed.png`)" alt="img" />
                  </div>
                  <div v-else>
                    <img :src="voucherImage(1, scope.row.tier, 'inactive')" alt="img" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                :label="$t('promotion.referFriends.table.despositSuccess')"
                min-width="120"
              >
                <template slot-scope="scope">
                  <div v-if="['RD', 'VR', 'RT', 'RR'].includes(scope.row.status)">
                    <img :src="voucherImage(2, scope.row.tier, 'active')" alt="img" />
                  </div>
                  <div v-else-if="['RE', 'X'].includes(scope.row.status)">
                    <img :src="require(`@/assets/images/promotion/referFriends/redeemed.png`)" alt="img" />
                  </div>
                  <div v-else>
                    <img :src="voucherImage(2, scope.row.tier, 'inactive')" alt="img" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                :label="$t('promotion.referFriends.table.volumeCompleted')"
                min-width="120"
              >
                <template slot-scope="scope">
                  <div v-if="['VR', 'RT', 'RR'].includes(scope.row.status)">
                    <img :src="voucherImage(3, scope.row.tier, 'active')" alt="img" />
                  </div>
                  <div v-else-if="['RE', 'X'].includes(scope.row.status)">
                    <img :src="require(`@/assets/images/promotion/referFriends/redeemed.png`)" alt="img" />
                  </div>
                  <div v-else>
                    <img :src="voucherImage(3, scope.row.tier, 'inactive')" alt="img" />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :md="5" :sm="24" :xs="24" class="table-redeem">
          <div class="input-box">
            <div class="background">
              <div class="background-text" v-html="$t('promotion.referFriends.totalRedeemableTitle')"></div>
            </div>
            <div class="content-wrapper">
              <div class="content">
                <el-input v-model="totalRedeemableVoucher" :disabled="true"></el-input>
              </div>
              <div class="button-wrapper">
                <el-button
                  class="btn-red"
                  data-testid="refereeRedeem"
                  @click="showChooseAccount"
                  :disabled="isRedeemAllDisabled"
                >
                  {{ $t('promotion.referFriends.redeem') }}
                </el-button>
              </div>
              <div class="content padContent">{{ $t('promotion.referFriends.totalRedeemableTip') }}</div>
              <div class="content padContent">{{ $t('promotion.referFriends.peroidForAudit') }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <ChooseAccount
      :chooseAccountVisible.sync="chooseAccountVisible"
      :amount="totalRedeemableVoucher"
      :qualifiedIDList="qualifiedIDList"
      :overBonus.sync="overBonus"
      @successfulCallback="successfulCallback"
    ></ChooseAccount>
  </div>
</template>

<script>
import { apiReferralLink } from '@/resource';
import Number from '@/components/promotion/referFriends/Number';
import AddToAny from '@/components/promotion/referFriends/AddToAny';
import ChooseAccount from '@/components/promotion/referFriends/ChooseAccount';
import screenSizePad from '@/mixins/screenSizePad';
import MobileBox from '@/components/promotion/referFriends/MobileBox';

export default {
  name: 'RefereeSection',
  components: { Number, ChooseAccount, AddToAny, MobileBox },
  props: {
    isFinishedReferee: Boolean,
    redeemedReferralCount: Number,
    totalRedeemedVoucher: Number,
    totalRedeemableVoucher: Number,
    tableData: Array,
    isRefereeOverBonus: Boolean,
    isReferrer: Boolean,
    showReferrer: Boolean
  },
  mixins: [screenSizePad],
  data() {
    return {
      referralLink: '',
      chooseAccountVisible: false,
      qualifiedIDList: []
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    isRedeemAllDisabled() {
      return this.totalRedeemableVoucher === 0;
    },
    countries() {
      return this.$store.state.register.countries;
    },
    overBonus() {
      return this.isRefereeOverBonus;
    }
  },
  methods: {
    successfulCallback() {
      this.$emit('successfulCallback');
    },
    showChooseAccount() {
      this.qualifiedIDList = this.tableData.filter(x => ['VR', 'RR'].includes(x.status)).map(x => x.participantId);
      this.chooseAccountVisible = true;
    },
    voucherImage(statusNo, tier, isActive) {
      let imageMap = {
        1: {
          1: {
            active: require(`@/assets/images/promotion/referFriends/20dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/20dollar_grey.png`)
          },
          2: {
            active: require(`@/assets/images/promotion/referFriends/20dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/20dollar_grey.png`)
          }
        },
        2: {
          1: {
            active: require(`@/assets/images/promotion/referFriends/50dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/50dollar_grey.png`)
          },
          2: {
            active: require(`@/assets/images/promotion/referFriends/30dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/30dollar_grey.png`)
          }
        },
        3: {
          1: {
            active: require(`@/assets/images/promotion/referFriends/80dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/80dollar_grey.png`)
          },
          2: {
            active: require(`@/assets/images/promotion/referFriends/50dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/50dollar_grey.png`)
          }
        }
      };
      return imageMap[statusNo][tier][isActive];
    },
    getApiReferralLink() {
      // ACM-7664, ACM-7639
      const LNAGMAP = {
        zh_CN: 'cn',
        fr_FR: 'fr',
        th: 'th',
        de: 'de',
        es: 'es',
        vi: 'vn',
        id: 'id',
        ar: 'ar',
        ko: 'kr',
        ja: 'jp',
        pt: 'pt'
      };
      let preFix = LNAGMAP[this.lang] || '';
      // malay
      if (this.countryCode === '5015') {
        preFix = '';
      }

      const domain = preFix ? this.GLOBAL_REFERRAL_SITE.replace('www.', '') : this.GLOBAL_REFERRAL_SITE;

      apiReferralLink().then(resp => {
        if (resp.data.code === 0) {
          this.referralLink = `https://${preFix ?  `${preFix}.`: ''}${domain}/forex-trading-account/?c=` + resp.data.data;
        }
      });
    },
    getCountryName(code) {
      let countryInfo = this.countries.find(country => country.id === parseInt(code));
      return countryInfo ? countryInfo.countryName : '';
    },
    onCopy(e) {
      this.$message({
        message: this.$t('common.field.successful'),
        type: 'success'
      });
    },
    onError(e) {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error'
      });
    }
  },
  mounted() {
    this.getApiReferralLink();
    this.$store.dispatch('register/actionCountries', this.lang);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/referFriends.scss';
</style>
