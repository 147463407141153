<template>
  <div class="voucher-section">
    <div class="box">
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :sm="24" :xs="24" v-if="smallDevicesMatches">
          <div class="image-Wrapper">
            <div class="text-wrapper">
              <img src="@/assets/images/promotion/referFriends/referrer_and_referee.png" alt="img" />
              <h3 class="txt">
                {{ $t('promotion.referFriends.referrer') }}
              </h3>
              <h3 class="txt2">
                {{ $t('promotion.referFriends.referral') }}
              </h3>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :md="7" :sm="12" :xs="12"> <Voucher :tier.sync="referrerTier" :type="`referrer`"></Voucher></el-col>
        <el-col :md="10" v-if="!smallDevicesMatches">
          <div class="image-Wrapper">
            <div class="text-wrapper">
              <img src="@/assets/images/promotion/referFriends/referrer_and_referee.png" alt="img" />
              <h3 class="txt">
                {{ $t('promotion.referFriends.referrer') }}
              </h3>
              <h3 class="txt2">
                {{ $t('promotion.referFriends.referral') }}
              </h3>
            </div>
          </div>
        </el-col>
        <el-col :md="7" :sm="12" :xs="12"><Voucher :tier.sync="refereeTier" :type="`referee`"></Voucher> </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Voucher from '@/components/promotion/referFriends/Voucher';
import screenSizePad from '@/mixins/screenSizePad';

export default {
  name: 'VoucherDescriptionSection',
  components: { Voucher },
  mixins: [screenSizePad],
  props: {
    referrerTier: Number,
    refereeTier: Number
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/referFriends.scss';
@import '@/assets/css/components/promotion/voucherSection.scss';
</style>
