<template>
  <div>
    <div id="referFriends">
      <div class="banner-container">
        <div v-if="banner" :class="banner.bgClass">
          <BannerItemV2 v-bind="banner" :lang="lang" />
        </div>
      </div>
      <div class="content-box">
        <div class="inner">
          <h3>{{ $t('menu.referFriends') }}</h3>
          <ReferrerSection
            v-if="isReferrer && showReferrer"
            :tier.sync="referrerTier"
            :referrerRedeemableVoucher.sync="referrerRedeemableVoucher"
            :referrerStatus="referrerStatus"
            :referrerParticipantId.sync="referrerParticipantId"
            @successfulCallback="successfulCallback"
          ></ReferrerSection>
          <RefereeSection
            :isReferrer.sync="isReferrer"
            :showReferrer.sync="showReferrer"
            :redeemedReferralCount.sync="redeemedReferralCount"
            :totalRedeemableVoucher.sync="totalRedeemableVoucher"
            :totalRedeemedVoucher.sync="totalRedeemedVoucher"
            :tableData.sync="tableData"
            :isRefereeOverBonus="isRefereeOverBonus"
            @successfulCallback="successfulCallback"
          ></RefereeSection>
          <VoucherDescriptionSection
            :referrerTier.sync="referrerTier"
            :refereeTier.sync="referrerTier"
          ></VoucherDescriptionSection>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReferrerSection from '@/components/promotion/referFriends/ReferrerSection';
import RefereeSection from '@/components/promotion/referFriends/RefereeSection';
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import { banners } from '@/constants/banners';
import VoucherDescriptionSection from '@/components/promotion/referFriends/VoucherDescriptionSection';
import { apiReferralList } from '@/resource';

export default {
  name: 'referFriends',
  components: { RefereeSection, ReferrerSection, VoucherDescriptionSection, BannerItemV2 },
  data() {
    return {
      referrerTier: null,
      tableData: [], // referee data
      isRefereeOverBonus: false, // is referee over bonus
      redeemedReferralCount: 0,
      totalRedeemableVoucher: null,
      totalRedeemedVoucher: null,
      isReferrer: true,
      referrerRedeemableVoucher: null,
      referrerStatus: null,
      referrerParticipantId: null,
      banner: null
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    showReferrer() {
      return this.referrerStatus ? !['X', 'RR'].includes(this.referrerStatus) : true;
    }
  },
  methods: {
    getapiReferralList() {
      apiReferralList().then(resp => {
        if (resp.data.code === 0) {
          this.tableData = resp.data.data.rafParticipants; // referee data
          this.isRefereeOverBonus = resp.data.data.overBonus || false; // is referee over bonus
          this.redeemedReferralCount = resp.data.data.redeemedRafParticipantCount;
          this.totalRedeemableVoucher = resp.data.data.totalRedeemableVoucher.amount; //default - USD
          this.totalRedeemedVoucher = resp.data.data.totalRedeemedVoucher.amount; //default - USD
          this.referrerTier = resp.data.data.rafParticipantTier;
          this.referrerRedeemableVoucher = resp.data.data.rafParticipant ? resp.data.data.rafParticipant.amount : 0;
          this.referrerStatus = resp.data.data.rafParticipant ? resp.data.data.rafParticipant.status : null;
          this.isReferrer = resp.data.data.rafParticipant ? true : false;
          this.referrerParticipantId = resp.data.data.rafParticipant
            ? resp.data.data.rafParticipant.participantId
            : null;
        }
      });
    },
    successfulCallback() {
      this.$message({
        message: this.$t('promotion.successful'),
        type: 'success',
        duration: 5000
      });
      this.referrerRedeemableVoucher = 0;
      this.totalRedeemableVoucher = 0;
      setTimeout(() => {
        this.getapiReferralList();
      }, 4000);
    },
    getBanner() {
      this.banner = banners.filter(el => el.promotionType === 'REFER_A_FRIEND_3')[0];
    }
  },
  mounted() {
    this.getapiReferralList();
    this.getBanner();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/referFriends.scss';
</style>
