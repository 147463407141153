<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :md="12" :sm="14">
        <div class="intro-text">
          {{ $t('promotion.referFriends.intro', { amount: tier === 1 ? '$100' : '$50' }) }}
        </div></el-col
      >
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :md="2" :sm="3" :xs="6">
        <img src="@/assets/images/promotion/dash.png" class="image-dash" alt="img"
      /></el-col>
    </el-row>
    <div class="referrers_section">
      <el-row :gutter="40">
        <el-col :md="6" :sm="24" :xs="24" v-for="step in stepList" :key="step.step">
          <div class="box" :class="{ active_box: referrerStep >= step.step }">
            <div class="top">
              <div class="shadow"></div>
              <div class="title">{{ step.title }}</div>
            </div>
            <div class="bottom">
              <div class="content placeholder">
                <img :src="stepIcon(step.step)" alt />
              </div>
              <div class="content blue_info">
                {{ step.content }}
              </div>
              <div class="content">
                <img :src="stepVoucherImage(step.step)" alt />
              </div>
            </div>
            <div class="background"></div>
          </div>
        </el-col>
        <el-col :md="6" :sm="24" :xs="24">
          <div class="input-box">
            <div class="background">
              <div class="background-text" v-html="$t('promotion.referFriends.totalEanringTitle')"></div>
            </div>
            <div class="content-wrapper">
              <div class="content">
                <el-input v-model="shownReferrerRedeemableVoucher" :disabled="true"></el-input>
              </div>
              <div class="button-wrapper">
                <el-button
                  class="btn-red"
                  data-testid="referrerRedeem"
                  @click="showChooseAccount"
                  :disabled="isRedeemAllDisabled"
                >
                  {{ $t('promotion.referFriends.redeem') }}
                </el-button>
              </div>
              <div class="content padContent">{{ $t('promotion.referFriends.totalEarningTip') }}</div>
              <div class="content padContent">{{ $t('promotion.referFriends.peroidForAudit') }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="unuseful-section" v-if="!smallDevicesMatches">
      <el-row :gutter="40">
        <el-col :sm="6" :xs="24" class="stepline_wrapper">
          <el-row :gutter="5">
            <el-col :span="5">
              <img :src="stepImage(1)" alt="img" />
            </el-col>
            <el-col :span="19">
              <img :src="stepLineImage(1)" alt="img" class="stepline" />
            </el-col>
          </el-row>
        </el-col>
        <el-col :sm="6" :xs="24" class="stepline_wrapper">
          <el-row :gutter="5">
            <el-col :span="5">
              <img :src="stepImage(2)" alt="img" />
            </el-col>
            <el-col :span="19">
              <img :src="stepLineImage(2)" alt="img" class="stepline" />
            </el-col>
          </el-row>
        </el-col>
        <el-col :sm="6" :xs="24" class="stepline_wrapper">
          <el-row :gutter="5">
            <el-col :span="5"><img :src="stepImage(3)" alt="img"/></el-col>
            <el-col :span="19"><img :src="stepLineImage(3)" alt="img" class="stepline"/></el-col>
          </el-row>
        </el-col>
        <el-col :sm="6" :xs="24">
          <el-row :gutter="5">
            <el-col :span="5"><img :src="stepImage(3)" alt="img"/></el-col>
            <el-col :span="16">
              <img :src="stepLineImage(3)" alt="img" class="stepline" />
            </el-col>
            <el-col :span="3"><img :src="dotImage()" alt="img" class="stepline"/></el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <ChooseAccount
      :chooseAccountVisible.sync="chooseAccountVisible"
      :amount="referrerRedeemableVoucher"
      :qualifiedIDList="qualifiedIDList"
      :overBonus.sync="overBonus"
      @successfulCallback="successfulCallback"
    ></ChooseAccount>
  </div>
</template>

<script>
import ChooseAccount from '@/components/promotion/referFriends/ChooseAccount';
import screenSizePad from '@/mixins/screenSizePad';

export default {
  name: 'ReferrerSection',
  components: { ChooseAccount },
  mixins: [screenSizePad],
  props: {
    tier: Number,
    referrerRedeemableVoucher: Number,
    referrerStatus: String,
    referrerParticipantId: Number
  },
  data() {
    return {
      chooseAccountVisible: false,
      qualifiedIDList: [],
      stepList: [
        {
          step: 1,
          title: this.$t('promotion.referFriends.step1'),
          content: this.$t('promotion.referFriends.step1Descp')
        },
        {
          step: 2,
          title: this.$t('promotion.referFriends.step2'),
          content: this.$t('promotion.referFriends.step2Descp')
        },
        {
          step: 3,
          title: this.$t('promotion.referFriends.step3'),
          content: this.$t('promotion.referFriends.step3Descp')
        }
      ]
    };
  },
  computed: {
    shownReferrerRedeemableVoucher() {
      return ['RE', 'VR', 'RT'].includes(this.referrerStatus) ? this.referrerRedeemableVoucher : 0;
    },
    isRedeemAllDisabled() {
      return this.referrerRedeemableVoucher === 0 || !['RE', 'VR', 'RT'].includes(this.referrerStatus);
    },
    referrerStep() {
      if (['RO'].includes(this.referrerStatus)) {
        return 1;
      } else if (['RD'].includes(this.referrerStatus)) {
        return 2;
      } else if (['RE', 'VR', 'X', 'RR', 'RT'].includes(this.referrerStatus)) {
        return 3;
      } else {
        return 0;
      }
    },
    overBonus() {
      return this.referrerStatus ? this.referrerStatus === 'RT' : false;
    }
  },
  methods: {
    successfulCallback() {
      this.$emit('successfulCallback');
    },
    showChooseAccount() {
      this.qualifiedIDList = [this.referrerParticipantId];
      this.chooseAccountVisible = true;
    },
    dotImage() {
      return this.referrerStep >= 3
        ? require(`@/assets/images/promotion/referFriends/dot.png`)
        : require(`@/assets/images/promotion/referFriends/dot_grey.png`);
    },
    stepImage(stepNo) {
      return this.referrerStep >= stepNo
        ? require(`@/assets/images/promotion/referFriends/step.png`)
        : require(`@/assets/images/promotion/referFriends/step_grey.png`);
    },
    stepLineImage(stepNo) {
      return this.referrerStep >= stepNo
        ? require(`@/assets/images/promotion/referFriends/step_dotted_line.png`)
        : require(`@/assets/images/promotion/referFriends/step_dotted_line_grey.png`);
    },
    stepIcon(stepNo) {
      let isActive = this.referrerStep >= stepNo ? 'active' : 'inactive';
      let imageMap = {
        1: {
          active: require(`@/assets/images/promotion/referFriends/step1.png`),
          inactive: require(`@/assets/images/promotion/referFriends/step1_grey.png`)
        },
        2: {
          active: require(`@/assets/images/promotion/referFriends/step2.png`),
          inactive: require(`@/assets/images/promotion/referFriends/step2_grey.png`)
        },
        3: {
          active: require(`@/assets/images/promotion/referFriends/step3.png`),
          inactive: require(`@/assets/images/promotion/referFriends/step3_grey.png`)
        }
      };
      return imageMap[stepNo][isActive];
    },
    stepVoucherImage(stepNo) {
      let isActive = this.referrerStep >= stepNo ? 'active' : 'inactive';
      let imageMap = {
        1: {
          1: {
            active: require(`@/assets/images/promotion/referFriends/20dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/20dollar_grey.png`)
          },
          2: {
            active: require(`@/assets/images/promotion/referFriends/10dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/10dollar_grey.png`)
          }
        },
        2: {
          1: {
            active: require(`@/assets/images/promotion/referFriends/30dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/30dollar_grey.png`)
          },
          2: {
            active: require(`@/assets/images/promotion/referFriends/20dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/20dollar_grey.png`)
          }
        },
        3: {
          1: {
            active: require(`@/assets/images/promotion/referFriends/50dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/50dollar_grey.png`)
          },
          2: {
            active: require(`@/assets/images/promotion/referFriends/20dollar.png`),
            inactive: require(`@/assets/images/promotion/referFriends/20dollar_grey.png`)
          }
        }
      };
      return this.tier ? imageMap[stepNo][this.tier][isActive] : null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/referFriends.scss';
@import '@/assets/css/components/promotion/referrerSection.scss';
</style>
