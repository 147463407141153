<template>
  <div class="info-number">
    <div class="number-wrapper" v-for="(number, index) in numberArr" :key="`number-${index}`">
      <img src="@/assets/images/promotion/referFriends/number_container.png" alt="img" />
      <h3 class="txt">
        {{ number }}
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Number',
  props: {
    number: Number
  },
  computed: {
    numberArr() {
      return [...`${this.number}`].map(Number);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/referFriends.scss';
</style>
